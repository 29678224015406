<template>

  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-end v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset"></button>
        </div>
      </div>
    </div>
  </div>

  <div class="position-absolute v-content d-flex align-items-center text-center z-1">
    <div id="adobe-dc-view"></div>
    {{ assetTypes }}
    <!-- <script type="text/javascript">
      document.addEventListener("adobe_dc_view_sdk.ready", function()
      {
        var adobeDCView = new AdobeDC.View({clientId: "69a368e01ff74429b7c757f3b8ef3bd5", divId: "adobe-dc-view"});
        adobeDCView.previewFile(
      {
        content:   {location: {url: "<?php echo($p); ?>"}},
        metaData: {fileName: "<?php echo($m); ?>"}
      }, {defaultViewMode: "FIT_WIDTH", showAnnotationTools: false, showLeftHandPanel: false, 
          showPageControls: false, showDownloadPDF: false, showPrintPDF: false});
      });
    </script> -->
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "FloorplanView",
  props: [
    'order'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
    }
  },
  created() {
    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          id: j++
        };
      });

      console.log(menuItems)
      console.log(this.folderStructure)
      console.log(this.assetType)

      // Custom order array
      this.assetTypes = menuItems;
      // this.assetTypes = this.reorder(menuItems, this.theme.floorplansOrder);
    })
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js')
    document.head.appendChild(externalScript)
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>