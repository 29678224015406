<template>

  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-end v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute v-content d-flex align-items-center text-center z-1">
    <iframe :src="`https://iframe.mediadelivery.net/embed/${collectionId}/${guid}?autoplay=true&loop=true`"
      loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"

export default {
  name: "VideoView",
  props: [
    'guid',
    'collectionId'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {

    }
  },
  created() {
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>