import Axios from 'axios'
import global from '../global'

const {theme} = global
const metaluxeApiClient = Axios.create({ baseURL: theme.apiUrl })
const bunnyApiClient = Axios.create({ 
  baseURL: theme.bunnyApiUrl,
  headers: {
    'AccessKey': theme.accessKey
  }
})

export default {
  getFolders(folderStructure) {
    return metaluxeApiClient.get('/folders/' + theme.storageApiPassword + '/' + folderStructure)
  },
  
  getRenders(folderStructure) {
    return metaluxeApiClient.get('/renders/' + theme.storageApiPassword + '/' + folderStructure)
  },
  
  getCollections(libraryId, searchQuery) {
    if(searchQuery) {
      return metaluxeApiClient.get('/collections/' + libraryId + '/' + searchQuery + '/' + theme.streamApiKey)
    } else {
      return metaluxeApiClient.get('/collections/' + libraryId + '/' + theme.streamApiKey)
    }
  },
  
  getVideos(libraryId, collectionId) {
    return metaluxeApiClient.get('/videos/' + libraryId + '/' + collectionId + '/' + theme.streamApiKey)
  },
  
  purgeCache() {
    return bunnyApiClient.post('/purge', null, { params: {
        url: theme.cdnUrl
      }
    })
  }
}