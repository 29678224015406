<template>
  <div class="container d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
        <a href="#" class="btn-back opacity-0"></a>
        <a href="#" class="m-auto"><img src="/img/ui-theme/aspire-header-logo.svg" /></a>
        <button class="btn d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src="img/ui-theme/contact-icon.svg" alt="Fullscreen"></button>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center z-2">
        <div class="row g-0">
          <div class="col-12">
            <template v-if="!isLoading">
              <EventCard v-for="event in events" :key="event.id" :event="event" />
            </template>
            <p v-else>
              Loading events
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex align-items-end justify-content-end sticky-bottom theme-footer">
        <img src="/img/ui-theme/kollosche-footer-logo.svg" class="position-relative theme-footer-logo"/>
      </div>
    </div>
  </div>
</template>

<script>
import configData from "@/assets/config.json";
import BunnyNetService from "@/services/BunnyNetService.js";
import EventCard from '@/components/EventCard'

export default {
  name: "DefaultView",
  components: { EventCard },
  data() {
    return {
      configs: configData,
      isLoading: true,
      events: []
    }
  },
  created () {
    BunnyNetService.getRenders()
    .then(response => {
      this.events = response.data
      this.isLoading = false
      console.log(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }
};
</script>