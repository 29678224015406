import { reactive } from "vue";

const theme = reactive({
  agentLogo: "colliers-footer-logo.svg",
  devLogo: "florian-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "1e310d16-563b-4418-959f-033e6c35d5de",
  videoLibraryId: "56659",
  streamCdnUrl: "https://vz-11377a07-547.b-cdn.net",
  streamApiKey: "b91d6fe7-2466-4629-a2d59f1db490-eee6-4373",
  storageApiPassword: "5344b830-b9e4-4678-8c6404b3aa1c-000a-4ce1",
  cdnUrl: "https://florian.metaluxe.com.au",
  devFolder: "/florian",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  
  // top level menu
  assetsMenuOrder: [
    'type-a',
    'type-b',
    'type-c',
    'rooftop-amenities',
    'exterior'
  ],
  
  typeAMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  typeBMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  typeCMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],

  amenitiesMenuOrder: [
    'videos',
    'virtual-tour'
  ],

  exteriorMenuOrder: [
    'videos'
  ],
  
  typeAAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Type A'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/pKlyrB_EF8KW?mls=1'
    ],
    floorplans: [ 0, 1 ],
    floorplansName: [
      'Type A',
      'Type A Courtyard'
    ],
    floorplansUrl: [
      ''
    ],
    balcony: [ 0 ],
    balconyName: [
      'Balcony Views'
    ],
    balconyUrl: [
      'https://florianbalconyviews.metaluxe.com.au/typea/index.htm'
    ]
  },
  
  typeBAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Courtyard Walkthrough',
      'Non-Courtyard Walkthrough'
    ],
    panoramas: [ 0, 1 ],
    panoramasName: [
      'Type B Courtyard',
      'Type B Non-Courtyard'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/ZPBUg-HRA',
      'https://app.cloudpano.com/tours/SaxEGNnAH'
    ],
    floorplans: [ 0, 1 ],
    floorplansName: [
      'Type B',
      'Type B Courtyard'
    ],
    floorplansUrl: [
      ''
    ],
    balcony: [ 0 ],
    balconyName: [
      'Balcony Views'
    ],
    balconyUrl: [
      'https://florianbalconyviews.metaluxe.com.au/typeb/index.htm'
    ]
  },
  
  typeCAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Type C'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/FxZKKTooTY6e?mls=1'
    ],
    floorplans: [ 0, 1 ],
    floorplansName: [
      'Type C',
      'Type C Courtyard'
    ],
    floorplansUrl: [
      ''
    ],
    balcony: [ 0 ],
    balconyName: [
      'Balcony Views'
    ],
    balconyUrl: [
      'https://florianbalconyviews.metaluxe.com.au/typec/index.htm'
    ]
  },
  
  // amenities asset order
  amenitiesAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Rooftop Amenities'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/ESCc8NiT2UO?mls=1'
    ]
  },
  
  // exterior asset order
  exteriorAsset: {
    videos: [ 0 ],
    videosName: [
      'Cinematic'
    ]
  },
});

export default { theme };